import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../utils/axios";

const initialState = {
  data: [],
  loading: false,
  message: "",
  completed: false,
  hasError: false,
};

export const fetchItemData = createAsyncThunk(
  "itemData/fetchItemData",
  async ({ id, params }) => {
    const idPrefix = id.split("-")[0];
    let path = "";
    switch (idPrefix) {
      case "series":
        path = "/series/search/v3";
        break;
      case "film":
        path = "/movies/search/v3";
        break;
      case "game":
        path = "/games/search";
        break;
      case "franchise":
        path = "/franchise/search";
        break;
      case "companies":
        path = "/company/search";
        break;
      case "book":
        path = "/books/search";
        break;
      default:
        if (idPrefix.startsWith("co")) {
          path = "/company/search";
        }
    }

    const response = await axiosInstance.request({
      url: path,
      method: "GET",
      params: {
        ...params,
        ip_id: id,
      },
    });

    return response;
  }
);



const itemDataSlice = createSlice({
  name: "itemData",
  initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchItemData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchItemData.fulfilled, (state, action) => {
        state.loading = false;
        state.completed = true;
        state.data = action.payload.data;
        if (state.data?.tiktok) {
          state.data.tiktok = state.data.tiktok.split(",").map((x) => x.trim());
        }
        if (state.data?.instagram) {
          state.data.instagram = state.data.instagram
            .split(",")
            .map((x) => x.trim());
        }
      })
      .addCase(fetchItemData.rejected, (state, action) => {
        state.loading = false;
        state.completed = true;
        state.hasError = true;
        state.message = action.error.message;
      });
  },
});

const itemDataReducer = itemDataSlice.reducer;

export default itemDataReducer;

export const itemDataActions = itemDataSlice.actions;
