import {
  ExpandableSection,
  Link,
  Multiselect,
  SpaceBetween
} from "@cloudscape-design/components";
import Toggle from "@cloudscape-design/components/toggle";
import {
  GridApi,
  GridReadyEvent,
  TooltipRendererParams
} from "ag-grid-community";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { rgb } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { format } from "date-fns";
import { sum } from 'lodash';
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomLoadingOverlay from "../../../../components/PantheonLoading";
import { RangeDateSelector } from "../../../../components/RangeDateSelector";
import VideoTitleRenderer from "../../../../components/VideoTitleRenderer";
import { YOUTUBE_CATEGORIES } from "../../../../config-global";
import { useGetGridTheme } from "../../../../hooks/UseTheme/useGetGridTheme";
import shortenInteger from "../../../../utils/shortenInteger";
import { useGetYoutubeCategoryData } from "../api/hooks/useGetYoutubeCategoryData";
import { CategoryChart } from "./CategoryChart";

function invertRank(rank: number | null): number {
  return rank !== null && rank > 0 ? 201 - rank : 0;
}

function tooltipRenderer(params: TooltipRendererParams) {
  const { context, xValue } = params;
  const rowData = context.data;
  const dateStrings = Object.keys(rowData.rankTrend).sort(
    (a, b) => new Date(a).getTime() - new Date(b).getTime()
  );
  const dateString = dateStrings[xValue];
  const date = new Date(dateString);
  const formattedDate = format(date, "dd MMM, yyyy");
  const originalRank = rowData.rankTrendData[xValue];
  const rank = originalRank === 0 ? "Not in top 200" : 201 - originalRank;
  return `<div class='dark:bg-slate-100 bg-slate-900 rounded-md p-2 text-slate-100 dark:text-slate-900'>
              <div class='tooltip-title'>${formattedDate}</div>
              <div class='tooltip-content'>
                <div>Rank: ${rank}</div>
              </div>
          </div>`;
}

const CategoryComponent: React.FC = () => {
  const [rowData, setRowData] = useState<any[]>([]);
  const [isLoadingState, setIsLoadingState] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [dateRange, setDateRange] = useState<any>({
    type: "relative",
    amount: 1,
    unit: "month",
    startDate: moment().subtract(1, "month").unix(),
    endDate: moment().unix(),
  });

  const [selectedCategories, setSelectedCategories] = useState([
    {
      label: "All",
      value: "All",
    },
  ]);
  const gridApiRef = useRef<GridApi | null>(null);

  const { data: categoryData, error: categoryDataError, isLoading, refetch } =
    useGetYoutubeCategoryData(dateRange.startDate, dateRange.endDate);

  const { theme } = useGetGridTheme();
  const isDarkTheme = theme === 'ag-theme-quartz-dark';

  useEffect(() => {
    if (categoryData && Array.isArray(categoryData)) {
      const gridData = categoryData.flatMap((category) => [
        {
          path: [category.category],
          category: category.category,
          videoCount: category.total_count,
        },
        ...category.channels.flatMap((channel) => [
          {
            path: [category.category, channel.channelTitle],
            channelTitle: channel.channelTitle,
            channelId: channel.channelId,
            videoCount: channel.count,
          },
          ...channel.videos.map((video) => {
            const rankTrend = video.rank_trend;
            const rankTrendData = Object.entries(rankTrend)
              .sort(([dateA], [dateB]) => new Date(dateA).getTime() - new Date(dateB).getTime())
              .map(([_, rank]) => invertRank(rank as number));

            return {
              path: [category.category, channel.channelTitle, video.title],
              videoTitle: video.title,
              videoId: video.video_id,
              videoCount: video.count,
              views: video.latest_stats.view_count,
              likes: video.latest_stats.like_count,
              comments: video.latest_stats.comment_count,
              rankTrend,
              rankTrendData,
            };
          }),
        ]),
      ]);

      const filteredData = selectedCategories.some((cat) => cat.value === "All")
        ? gridData
        : gridData.filter((item) => {
          return selectedCategories.some((cat) => cat.value === item.path[0]);
        });

      const totalViews = sum(filteredData.map(item => item.views || 0));

      const dataWithPercentage = filteredData.map(item => ({
        ...item,
        viewPercentage: item.views ? (item.views / totalViews) * 100 : 0
      }));

      setRowData(dataWithPercentage);
      setIsLoadingState(false);
    }
  }, [categoryData, isExpanded, selectedCategories]);

  useEffect(() => {
    if (!isLoadingState && isExpanded && gridApiRef.current) {
      setTimeout(() => {
        gridApiRef.current?.expandAll();
      }, 100);
    }
  }, [isLoadingState, isExpanded]);

  const getHeatmapColor = useCallback((value: number, max: number, startColor: string, endColor: string) => {
    const colorScale = interpolateRgb(startColor, endColor);
    const interpolatedColor = rgb(colorScale(value / max));
    return `rgb(${interpolatedColor.r}, ${interpolatedColor.g}, ${interpolatedColor.b})`;
  }, []);

  const getViewHeatmapColor = useCallback((value: number) => {
    return getHeatmapColor(
      value,
      100, // for percentage, max is always 100
      isDarkTheme ? "#0A211A" : "#e6f4ea",
      isDarkTheme ? "#2d9474" : "#059669"
    );
  }, [getHeatmapColor, isDarkTheme]);

  const getTextColor = useCallback((value: number) => {
    const intensity = value / 100;
    return isDarkTheme
      ? intensity > 0.2 ? '#ffffff' : '#d1d5db'
      : intensity > 0.4 ? '#ffffff' : '#000000';
  }, [isDarkTheme]);

  const heatmapCellRenderer = (params: any) => {
    const cellValue = params.value || 0;
    const backgroundColor = getViewHeatmapColor(cellValue);
    const textColor = getTextColor(cellValue);

    return (
      <div
        style={{
          backgroundColor,
          color: textColor,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '5px',
        }}
      >
        {cellValue.toFixed(2)}%
      </div>
    );
  };

  const columnDefs = [
    { field: "category", rowGroup: true, hide: true },
    { field: "channelTitle", rowGroup: true, hide: true },
    {
      headerName: "Times in top 200",
      field: "videoCount",
      cellRenderer: (params) => shortenInteger(params.value),
      minWidth: 150,
    },
    {
      field: "views",
      cellRenderer: (params) => shortenInteger(params.value),
      aggFunc: "sum",
    },
    {
      headerName: "% of Views",
      field: "viewPercentage",
      cellRenderer: heatmapCellRenderer,
      aggFunc: (params) => {
        const sum = params.values.reduce((acc, val) => acc + val, 0);
        return sum;
      },
      minWidth: 120,
    },
    {
      field: "likes",
      aggFunc: "sum",
      cellRenderer: (params) => shortenInteger(params.value),
    },
    {
      field: "comments",
      aggFunc: "sum",
      cellRenderer: (params) => shortenInteger(params.value),
    },
    {
      field: "rankTrendData",
      headerName: "Rank Trend",
      cellRenderer: "agSparklineCellRenderer",
      sortable: false,
      cellRendererParams: {
        sparklineOptions: {
          type: "column",
          fill: "#1976d2",
          stroke: "#1976d2",
          paddingInner: 0.3,
          axis: { stroke: "#e0e0e0" },
          tooltip: { renderer: tooltipRenderer },
          yAxis: { type: "number", min: 0, max: 200 },
        },
      },
      minWidth: 150,
    },
  ];

  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    sortable: true,
    suppressHeaderMenuButton: true,
  };

  const autoGroupColumnDef = {
    headerName: "Title",
    minWidth: 300,
    flex: 4,
    autoHeight: true,
    cellRendererParams: {
      suppressCount: true,
      innerRenderer: (params: any) => {
        if (params.node.level === 0) {
          const childCount = params.node.childrenAfterFilter
            ? params.node.childrenAfterFilter.length
            : 0;
          return `${params.value} (${childCount})`;
        } else if (params.node.level === 1) {
          const channelId = params.data.channelId;
          const channelLink = `https://www.youtube.com/channel/${channelId}`;
          const childCount = params.node.childrenAfterFilter
            ? params.node.childrenAfterFilter.length
            : 0;
          return (
            <Link href={channelLink} external>
              {params.data.channelTitle} ({childCount})
            </Link>
          );
        } else {
          return <VideoTitleRenderer
            node={params.node}
            data={{
              title: params.data.videoTitle,
              video_id: params.data.videoId
            }}
            value={params.value}
          />;
        }
      },
    },
  };

  const getDataPath = useCallback((data: any) => data.path, []);

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      gridApiRef.current = params.api;
      if (isExpanded) {
        params.api.expandAll();
      }
    },
    [isExpanded],
  );

  const handleExpandCollapseToggle = useCallback(
    ({ detail }: { detail: { checked: boolean } }) => {
      setIsExpanded(detail.checked);
      if (gridApiRef.current) {
        if (detail.checked) {
          gridApiRef.current.expandAll();
        } else {
          gridApiRef.current.collapseAll();
        }
      }
    },
    [],
  );

  const handleCategoryChange = useCallback(({ detail }) => {
    const newSelectedOptions = detail.selectedOptions;

    if (newSelectedOptions.some(option => option.value === "All") &&
      !selectedCategories.some(option => option.value === "All")) {
      setSelectedCategories([{ label: "All", value: "All" }]);
    }
    else if (!newSelectedOptions.some(option => option.value === "All") &&
      selectedCategories.some(option => option.value === "All")) {
      setSelectedCategories([]);
    }
    else {
      const filteredOptions = newSelectedOptions.filter(option => option.value !== "All");
      setSelectedCategories(filteredOptions.length > 0 ? filteredOptions : [{ label: "All", value: "All" }]);
    }
  }, [selectedCategories]);

  useEffect(() => {
    console.log('dateRange state updated:', dateRange);
  }, [dateRange]);

  if (categoryDataError)
    return <div>Error: {(categoryDataError as Error).message}</div>;

  return (
    <SpaceBetween size="xl" direction="vertical">
      <CategoryChart />
      <ExpandableSection
        defaultExpanded={true}
        headerText="Category Trends"
        headerActions={
          <SpaceBetween size="s" direction="horizontal">
            <div className="pt-1.5">
              <Toggle onChange={handleExpandCollapseToggle} checked={isExpanded}>
                {isExpanded ? "Collapse All" : "Expand All"}
              </Toggle>
            </div>
            <Multiselect
              selectedOptions={selectedCategories}
              onChange={handleCategoryChange}
              options={YOUTUBE_CATEGORIES}
              placeholder="Filter by category"
              hideTokens
            />
            <RangeDateSelector
              defaults={
                dateRange
                  ? dateRange?.type === "relative"
                    ? { ...dateRange, startDate: moment() .subtract(15, "days") .startOf("day") .format("YYYY/MM/DD"), endDate: moment(dateRange.endDate) .endOf("day") .format("YYYY/MM/DD"), }
                    : dateRange
                  : { type: "absolute", startDate: moment() .subtract(15, "days") .startOf("day") .format("YYYY/MM/DD"), endDate: moment().endOf("day").format("YYYY/MM/DD"), }
              }
              onChange={(e) => {
                if (e.type === "relative") {
                  const newDateRange = { 
                    startDate: moment(e.startDate).startOf("day").unix(), 
                    endDate: moment(e.endDate).endOf("day").unix(), 
                  };
                  setDateRange({
                    ...e,
                    ...newDateRange,
                  });
                } else {
                  const newDateRange = { 
                    startDate: moment(e.startDate).startOf("day").unix(), 
                    endDate: moment(e.endDate).endOf("day").unix(), 
                  };
                  setDateRange({
                    ...e,
                    ...newDateRange,
                  });
                }
              }}
            />
          </SpaceBetween>
        }
      >

        <div className={`${theme} h-[calc(80vh-200px)] min-h-[400px]`}>
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            loading={isLoading}
            loadingOverlayComponent={CustomLoadingOverlay}
            treeData={true}
            groupDefaultExpanded={0}
            getDataPath={getDataPath}
            groupAggFiltering={true}
            onGridReady={onGridReady}
            suppressAggFuncInHeader={true}
          />
        </div>
      </ExpandableSection>
    </SpaceBetween>
  );
};

export default CategoryComponent;
