import axiosInstance from "../../../utils/axios";

export interface GetPreviewDataProps {
  ipId: string;
};

export const getPreviewData = ({
  ipId,
}: GetPreviewDataProps) => {

  return axiosInstance.request({
    url: `/item/${ipId}/preview`,
    method: "GET",
  });
};