import { Alert, Box, Container, Link, Spinner } from '@cloudscape-design/components';
import "ag-grid-enterprise";
import { AgGridReact } from 'ag-grid-react';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useGetGridTheme } from '../../../hooks/UseTheme/useGetGridTheme';
import axiosInstance from '../../../utils/axios';
import renderTrend from '../../../utils/trendIcon';
import CustomLoadingOverlay from '../../../components/PantheonLoading';
import { useNavigate } from 'react-router';
import HoverPreview from '../../../components/HoverPreview/HoverPreview';

interface TrendingWidgetProps {
    title: any;
    payload: object;
    endpoint?: string;
    viewMoreHref?: string;
    timePeriod: 'daily' | 'weekly' | 'monthly';
}

interface TrendingData {
    results: Array<{
        daily_rank: number;
        daily_rank_change: number;
        daily_rank_trend: string;
        weekly_rank: number;
        weekly_rank_change: number;
        weekly_rank_trend: string;
        ip: string;
        ip_id: string;
        month_score: number[];
        release_date: string;
    }>;
}

const fetchTrendingData = async ({ queryKey }) => {

    const [_key, { endpoint, payload }] = queryKey;
    const { data } = await axiosInstance.request({
        url: endpoint,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        data: payload
    });
    return data;
}

const renderDailyRank = (params) =>
    renderTrend(params, "daily_rank", "daily_rank_change", "daily_rank_trend");

const renderWeeklyRank = (params) =>
    renderTrend(params, "weekly_rank", "weekly_rank_change", "weekly_rank_trend");

const renderMonthlyRank = (params) =>
    renderTrend(params, "monthly_rank", "monthly_rank_change", "monthly_rank_trend");

const releaseYear = (releaseDate) => {
    if (!releaseDate) return null;
    return new Date(releaseDate).getFullYear();
};

const TrendingWidget: React.FC<TrendingWidgetProps> = ({ title, payload, endpoint, viewMoreHref, timePeriod }) => {
    const { theme } = useGetGridTheme();

    const navigate = useNavigate();

    const defaultColDef = useMemo(
        () => ({
            sortable: true,
            flex: 1,
            suppressHeaderMenuButton: true,
            suppressHeaderFilterButton: true,
            filter: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        }),
        [],
    );

    const columnDefs = useMemo(() => {
        const getRankConfig = () => ({
            headerName: "Rank",
            cellRenderer: {
                daily: renderDailyRank,
                weekly: renderWeeklyRank,
                monthly: renderMonthlyRank
            }[timePeriod],
            valueGetter: (params) => parseInt(params.data[`${timePeriod}_rank`])
        });

        const allColumns = [
            {
                ...getRankConfig(),
                maxWidth: 90,
                cellDataType: "number",
                minWidth: 90,
                sortable: true,
            },
            {
                field: "ip",
                headerName: "Title",
                cellDataType: "text",
                minWidth: 100,
                cellRenderer: (params) => {
                    if (!params.value) return null;

                    const ipId = params.data.ip_id;
                    const releaseDate = params.data.release_date;
                    return (
                        <HoverPreview ipId={ipId}>
                            <Link href={`/item/${ipId}`}>
                                <span> {params.data.ip}
                                    <span className="text-xs text-slate-500"> {releaseYear(releaseDate)}</span>
                                </span>
                            </Link>
                        </HoverPreview>
                    );
                },
            },
            {
                field: "month_score",
                headerName: "Trend",
                cellRenderer: "agSparklineCellRenderer",
                cellRendererParams: {
                    sparklineOptions: {
                        type: "area",
                        fill: "#0972d3",
                    },
                },
            },
        ];
        return allColumns;
    }, [timePeriod]);

    const { data, error, isLoading } = useQuery(['trending', { endpoint, payload }], fetchTrendingData);

    if (error) return <Alert type="error">Error Fetching data</Alert>;

    return (
        <Container
            disableContentPaddings
            fitHeight
        >
            <div className="font-semibold bg-slate-200 p-2 dark:bg-slate-800 text-center truncate rounded-t-2xl">
                <Link
                    variant='secondary'
                    fontSize='heading-m'
                    onClick={() => {
                        navigate(viewMoreHref);
                    }}
                >
                    {title}
                </Link>
            </div>
            <hr className="border-slate-600 border-t-[1px]" />
            <div className={`${theme} compact h-86`}>
                <AgGridReact
                    rowData={data}
                    defaultColDef={defaultColDef}
                    suppressDragLeaveHidesColumns={true}
                    columnDefs={columnDefs}
                    suppressContextMenu={true}
                    loadingOverlayComponent={CustomLoadingOverlay}
                    loading={isLoading}
                />
            </div>
            
        </Container>
    );
}

export default TrendingWidget;
