import {
  Box,
  Button,
  Checkbox,
  ExpandableSection,
  Input,
  Modal,
  SpaceBetween,
  TokenGroup,
} from "@cloudscape-design/components";
import { useEffect, useMemo, useState } from "react";
import { VerticalIconBadge } from "../../../../../../components/VerticalIcon";
import { Vertical } from "../../../../../../types/verticalTypes";

const verticalLabelMap = {
  [Vertical.Movies]: "Movies",
  [Vertical.Series]: "Series",
  [Vertical.Games]: "Video Games",
};

export const RemoveTitlesFromFranchiseModal = ({
  visible,
  onDiscard,
  onSubmit,
  titles,
}) => {
  const [titleOptions, setTitleOptions] = useState(
    titles?.map((title) => ({
      value: title.ip_id,
      label: title.ip,
      checked: false,
      vertical: title.vertical,
    })) ?? [],
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);

  const verticals = useMemo(
    () => [...new Set(titles.map((title) => title.vertical))],
    [titles],
  );

  useEffect(() => {
    setTitleOptions(
      titles.map((title) => ({
        value: title.ip_id,
        label: title.ip,
        checked: false,
        vertical: title.vertical,
        releaseYear: title.release_date ? new Date(Date.parse(title.release_date)).getFullYear() : null,
      })),
    );
  }, [titles]);

  return (
    <Modal
      visible={visible}
      onDismiss={onDiscard}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onDiscard} disabled={loading}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                onSubmit(
                  titleOptions
                    .filter((title) => title.checked === true)
                    .map((title) => title.value),
                );
                setLoading(true);
              }}
              disabled={
                titleOptions.filter((title) => title.checked === true)
                  .length === 0
              }
              loading={loading}
            >
              Remove
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Remove titles from franchise"
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col">
          <Input
            type="search"
            placeholder="Search titles"
            value={searchQuery}
            onChange={({ detail }) => setSearchQuery(detail.value)}
          />
          {titleOptions.filter((title) => title.checked === true).length > 0 ? (
            <TokenGroup
              items={titleOptions.filter((title) => title.checked === true)}
              onDismiss={({ detail: { itemIndex } }) => {
                const dismissedItem = titleOptions.filter(
                  (title) => title.checked === true,
                )[itemIndex];
                const newTitleOptions = titleOptions.map((t, index) => {
                  if (t.value === dismissedItem.value) {
                    return {
                      ...t,
                      checked: false,
                    };
                  }
                  return t;
                });
                setTitleOptions(newTitleOptions);
              }}
            />
          ) : (
            <div className="py-2">No titles selected</div>
          )}
        </div>
        {verticals?.map((vertical) => (
          <ExpandableSection
            key={vertical}
            headerText={verticalLabelMap[vertical]}
            defaultExpanded
          >
            <div className="max-h-64 h-fit w-full overflow-y-auto flex flex-col gap-1">
              {titleOptions
                .filter((x) => x.vertical === vertical)
                .map((title) => (
                  <div
                    className={
                      (
                        searchQuery.length === 0
                          ? true
                          : title.label
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                      )
                        ? "block"
                        : "hidden"
                    }
                  >
                    <Checkbox
                      checked={title.checked}
                      onChange={({ detail }) => {
                        const newTitleOptions = titleOptions.map((t) => {
                          if (t.value === title.value) {
                            return {
                              ...t,
                              checked: detail.checked,
                            };
                          }
                          return t;
                        });
                        setTitleOptions(newTitleOptions);
                      }}
                    >
                      <span className="mr-2">
                        <VerticalIconBadge
                          vertical={title.vertical}
                          useColors
                        />
                      </span>
                      {title.label}
                      <span className="text-xs text-slate-600 dark:text-slate-400 ml-2">{title.releaseYear}</span>
                    </Checkbox>
                  </div>
                ))}
            </div>
          </ExpandableSection>
        ))}
      </div>
    </Modal>
  );
};
