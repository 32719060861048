import { Icon, Link } from "@cloudscape-design/components";

export const getRottenTomatoesAudienceScoreParams = (rtFanSummary) => {
  if (!rtFanSummary || rtFanSummary.score == null) {
    return {
      score: "-",
      icon: "/rticons/aud_score-empty.svg",
      alt: "No Score",
      isUpright: false,
      isSpilled: false,
      count: 0,
      isVerifiedHot: false,
    };
  }

  const isUpright =
    rtFanSummary.audienceRating === "Upright" ||
    rtFanSummary.rating === "Upright";
  const isSpilled =
    rtFanSummary.audienceRating === "Spilled" ||
    rtFanSummary.rating === "Spilled";
  const count = rtFanSummary.numReviews || rtFanSummary.count;
  const score = rtFanSummary.score;
  const isVerifiedHot = rtFanSummary["verified-hot"] === "true";

  return {
    score: score ? `${score}%` : "N/A",
    icon: isVerifiedHot
      ? "/rticons/aud_score-verifiedhot.svg"
      : isUpright
      ? "/rticons/aud_score-fresh.svg"
      : isSpilled
      ? "/rticons/aud_score-rotten.svg"
      : "/rticons/aud_score-empty.svg",
    alt: isVerifiedHot
      ? "Verified Hot"
      : isUpright
      ? "Upright"
      : isSpilled
      ? "Spilled"
      : "No Score",
    isUpright,
    isSpilled,
    count,
    isVerifiedHot,
  };
};

const RottenTomatesAudienceScore = ({ rtFanSummary, compact = false }) => {
  if (!rtFanSummary || rtFanSummary.score == null) {
    return (
      <div className="h-full flex flex-col justify-center">
        <div className="flex items-center space-x-2">
          <img
            src="/rticons/aud_score-empty.svg"
            alt="No Score"
            className={compact ? "h-6 w-6" : "h-12 w-12"}
          />
          <div className="text-black dark:text-slate-100">
            <div className={`${compact ? '' : 'text-xl font-bold'} dark:text-slate-50  leading-[1.25rem]`}>
              - %
            </div>
            {!compact && <div className="text-xs font-bold">Popcornmeter</div>}
          </div>
        </div>
      </div>
    );
  }

  const isUpright =
    rtFanSummary.audienceRating === "Upright" ||
    rtFanSummary.rating === "Upright";
  const isSpilled =
    rtFanSummary.audienceRating === "Spilled" ||
    rtFanSummary.rating === "Spilled";
  const count = rtFanSummary.numReviews || rtFanSummary.count;
  const score = rtFanSummary.score;
  const isVerifiedHot = rtFanSummary["verified-hot"] === "true";

  return (
    <div className="h-full flex flex-col justify-center">
      <div className="flex items-center space-x-2">
        {isVerifiedHot ? (
          <img
            src="/rticons/aud_score-verifiedhot.svg"
            alt="Verified Hot"
            className={compact ? "h-6 w-6" : "h-12 w-12"}
          />
        ) : isUpright ? (
          <img
            src="/rticons/aud_score-fresh.svg"
            alt="Upright"
            className={compact ? "h-6 w-6" : "h-12 w-12"}
          />
        ) : isSpilled ? (
          <img
            src="/rticons/aud_score-rotten.svg"
            alt="Spilled"
            className={compact ? "h-6 w-6" : "h-12 w-12"}
          />
        ) : null}
        <div className="text-black dark:text-slate-100">
          <div className={`${compact ? 'text-md' : 'text-lg font-bold'} dark:text-slate-50  leading-[1.25rem]`}>
            {score ? `${score}%` : "N/A"}
          </div>
          {!compact && (
            <>
              {rtFanSummary.url ? (
                <Link
                  href={rtFanSummary.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="text-xs font-bold">Popcornmeter <Icon name="external" size="inherit" /></div>
                </Link>
              ) : (
                <div className="text-xs font-bold">Popcornmeter</div>
              )}
            </>
          )}
          <div className="text-xs italic dark:text-slate-400">
            {parseInt(count, 10).toLocaleString()} ratings
          </div>
        </div>
      </div>
    </div>
  );
};

export default RottenTomatesAudienceScore;
