import { Box, Container, Link } from '@cloudscape-design/components';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import CustomLoadingOverlay from '../../../../../components/PantheonLoading';
import axiosInstance from '../../../../../utils/axios';
import { useNavigate } from 'react-router';

interface TopWidgetProps {
  title: any;
  endpoint: string;
  payload: any;
  viewMoreHref?: string;
}

const fetchTrendingData = async ({ queryKey }) => {
  const [_key, { endpoint, payload }] = queryKey;
  const { data } = await axiosInstance.request({
    url: endpoint,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: payload
  });
  return data;
};

const getImageUrl = (item) => {
  if (item.ip_id.startsWith("film-") || item.ip_id.startsWith("series-")) {
    return `https://image.tmdb.org/t/p/w300/${item.image_url}`;
  } else if (item.ip_id.startsWith("game-")) {
    return `https://images.igdb.com/igdb/image/upload/t_cover_big/${item.image_url}.png`;
  }
  return null;
}

const TopWidget: React.FC<TopWidgetProps> = ({ title, endpoint, payload, viewMoreHref }) => {

  const navigate = useNavigate();

  const [hoveredIndex, setHoveredIndex] = useState(0);
  const { data, error, isLoading } = useQuery(['trending', { endpoint, payload }], fetchTrendingData);

  if (error) {
    return <Box textAlign="center">Error fetching data</Box>;
  }

  return (
    <Container disableContentPaddings>
      <div className="font-bold bg-slate-200 dark:bg-slate-800 p-2 text-center truncate rounded-t-2xl">
        <Link
          variant='secondary'
          fontSize='heading-m'
          onClick={() => {
            navigate(viewMoreHref);
          }}
        >
          {title}
        </Link>
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center h-96">
          <CustomLoadingOverlay />
        </div>
      ) : (
        <div className="p-4 flex flex-col gap-4">
          <div className="flex gap-4">
            <img
              src={getImageUrl(data[hoveredIndex])}
              alt={data[hoveredIndex].ip}
              className="h-32 rounded-lg shadow-lg"
              loading="lazy"
            />
            <div>
              <div className="line-clamp-2 text-lg font-bold"><span className="dark:text-slate-400 text-slate-600 font-normal text-xl">{hoveredIndex + 1}.{" "}</span>{data[hoveredIndex].ip}</div>
            </div>
          </div>
          <hr className="border-b-1 border-gray-500/70"></hr>
          <ol className="list-decimal list-inside text-sm space-y-1">
            {data.slice(0, 10).map((item, index) => (
              <li key={index} className="truncate">
                <span
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(0)}
                >
                  <Link
                    variant='secondary'
                    href={`${window.location.origin}/item/${item.ip_id}`}>
                    {item.ip}
                  </Link>
                </span>
              </li>
            ))}
          </ol>
        </div>
      )}
    </Container>
  );
};

export default TopWidget;
