import React, { useMemo } from "react";
import { Vertical } from "../types/verticalTypes";

const getImageUrlSmall = (vertical, ip_id, image_url) => {
  switch (vertical) {
    case Vertical.Movies:
      return `https://image.tmdb.org/t/p/w92/${image_url}`;
    case Vertical.Series:
      return `https://image.tmdb.org/t/p/w92/${image_url}`;
    case Vertical.Games:
      return `https://images.igdb.com/igdb/image/upload/t_cover_small/${image_url}.png`;
    case Vertical.Franchises:
      return `https://images.searchpantheon.com/collections/${ip_id}_342.jpg`;
    default:
      return "";
  }
};

const getImageUrlMedium = (vertical, ip_id, image_url) => {
  switch (vertical) {
    case Vertical.Movies:
      return `https://image.tmdb.org/t/p/w342/${image_url}`;
    case Vertical.Series:
      return `https://image.tmdb.org/t/p/w342/${image_url}`;
    case Vertical.Games:
      return `https://images.igdb.com/igdb/image/upload/t_cover_big/${image_url}.png`;
    case Vertical.Franchises:
      return `https://images.searchpantheon.com/collections/${ip_id}_342.jpg`;
    default:
      return "";
  }
};

const getImageUrlBig = (vertical, ip_id, image_url) => {
  switch (vertical) {
    case Vertical.Movies:
      return `https://image.tmdb.org/t/p/w500/${image_url}`;
    case Vertical.Series:
      return `https://image.tmdb.org/t/p/w500/${image_url}`;
    case Vertical.Games:
      return `https://images.igdb.com/igdb/image/upload/t_cover_big_2x/${image_url}.png`;
    case Vertical.Franchises:
      return `https://images.searchpantheon.com/collections/${ip_id}_684.jpg`;
    default:
      return "";
  }
};

interface ItemImageProps {
  ipId: string;
  vertical: Vertical;
  imageUrl?: string;
  size?: "small" | "medium" | "big";
  [key: string]: any;
}

const ItemImage: React.FC<ItemImageProps> = ({
  ipId,
  vertical,
  imageUrl,
  size="small",
  ...props
}) => {

  const imageSrc = useMemo(() => {
    if (imageUrl) {
      switch (size) {
        case "small":
          return getImageUrlSmall(vertical, ipId, imageUrl);
        case "medium":
          return getImageUrlMedium(vertical, ipId, imageUrl);
        case "big":
          return getImageUrlBig(vertical, ipId, imageUrl);
      }
    }
    return null;
  }, [ipId, imageUrl, size]);

  const noImage = require("../assets/images/icons/no-image_342.jpg");

  return (
    <img 
      src={imageSrc || noImage} 
      loading="lazy" 
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = noImage;
      }}
      {...props} 
    />
  );
};

export default ItemImage;