import { Vertical } from "../../../types/verticalTypes";
import axiosInstance from "../../../utils/axios";

export interface GetNewsRiverDataProps {
  startTimestamp?: number;
  endTimestamp?: number;
  limit?: number;
  offset?: number;
  ipIds?: Array<string>;
  keywords?: Array<string>;
  exclude?: Array<string>;
  keywordFields?: Array<string>;
  excludeFields?: Array<string>;
  verticals?: Array<Vertical>;
  latest?: boolean;
};

export const getNewsRiverData = ({
  startTimestamp,
  endTimestamp,
  limit,
  offset,
  ipIds,
  keywords,
  exclude,
  keywordFields,
  excludeFields,
  verticals,
  latest,
}: GetNewsRiverDataProps) => {

  const params = {};
  if (startTimestamp) params["start"] = startTimestamp;
  if (endTimestamp) params["end"] = endTimestamp;
  if (limit) params["limit"] = limit;
  if (offset) params["offset"] = offset;
  if (ipIds && ipIds.length > 0) params["ip_id"] = ipIds.join(",");
  if (keywords && keywords.length > 0) params["keyword"] = keywords.join(",");
  if (exclude && exclude.length > 0) params["exclude"] = exclude.join(",");
  if (keywordFields && keywordFields.length > 0) params["keyword_fields"] = keywordFields.join(",");
  if (excludeFields && excludeFields.length > 0) params["exclude_fields"] = excludeFields.join(",");
  if (verticals && verticals.length > 0) params["vertical"] = verticals.map((v: Vertical) => v).join(",");
  if (latest) params["latest"] = latest;

  return axiosInstance.request({
    url: `/news/river`,
    method: "GET",
    params: params,
  });
};