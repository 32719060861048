import { Link } from "@cloudscape-design/components";
import React, { useMemo } from "react";
import { VerticalIcon } from "../../../../../../components/VerticalIcon";
import { CHART_LABELS, chartMap } from "../../../../../platforms/hooks/useGridConfig";
import { getAdditionalColumnDefinition } from "../../../../../platforms/utils/gridUtils";
import HoverPreview from "../../../../../../components/HoverPreview/HoverPreview";

const extraColumns = [
  CHART_LABELS.TRACKED,
  CHART_LABELS.FRANCHISE,
  CHART_LABELS.GENRE,
  CHART_LABELS.COUNTRIES,
  CHART_LABELS.OTT,
  CHART_LABELS.PLATFORM,
  CHART_LABELS.DISTRIBUTORS,
  CHART_LABELS.PRODUCTION,
  CHART_LABELS.DEVELOPER,
  CHART_LABELS.PUBLISHER,
  CHART_LABELS.THEME,
  CHART_LABELS.AGE_RATINGS,
  CHART_LABELS.GAME_MODE,
  CHART_LABELS.PLAYER_PERSPECTIVE,
  CHART_LABELS.AUTHOR,
  CHART_LABELS.BOOK_SERIES,
  CHART_LABELS.ISBN13,
];

export const useAddTitlesGridConfig = () => {

  const defaultColDef = {
    sortable: true,
    flex: 1,
    suppressHeaderMenuButton: true,
    suppressHeaderFilterButton: true,
    filter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    minWidth: 100,
    suppressMovable: true,
  } as any;

  const columnDefs = useMemo<any>(() => {
    const baseColumns = [
      {
        field: "ip",
        headerName: "Title",
        minWidth: 250,
        width: 270,
        flex: 1,
        pinned: "left",
        headerCheckboxSelection: true,
        checkboxSelection: true,
        showDisabledCheckboxes: true,
        suppressHeaderMenuButton: true,
        cellRenderer: (params) => {
          if (!params.value) return null;
          const ipId = params.data.ip_id;
          const release_year = params.data.release_date ? new Date(Date.parse(params.data.release_date)).getFullYear() : "N/A";
          return (
            <div className="flex gap-2 mt-0.5 align-middle">
              <span className="text-slate-500 flex justify-center">
                <VerticalIcon vertical={params.data.vertical} />
              </span>
              <HoverPreview ipId={ipId}>
                <Link href={`/item/${ipId}`}>
                  <span>{params.data.ip}</span>
                </Link>
              </HoverPreview>
              <span className="text-xs text-slate-500 mt-0.5">
                {release_year}
              </span>
            </div>
          );
        },
      },
      {
        field: "release_date",
        headerName: "Release Date",
        minWidth: 150,
      },
    ];

    const additionalColumns = extraColumns.map((key: string) => {
      const { field, headerName, cellDataType } = chartMap[key];
      const hide = false;
      const colDef = {
        ...getAdditionalColumnDefinition(
          field,
          headerName,
          cellDataType,
          hide,
        ),
        cellRenderer: (params) => {
          return (cellDataType === "boolean") ? params.value.toString() : (cellDataType === "object" && Array.isArray(params.value)) ? params.value.join(", ") : params.value;
        },
      };
      return colDef;
    });

    return [...baseColumns, ...additionalColumns];
  }, []);

  return {
    defaultColDef,
    columnDefs,
    rowSelection: "multiple" as "multiple",
  };
};