import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getPreviewData } from "../request";

export const useGetPreviewData = (params) => {

  const { isLoading, isFetching, data, error } = useQuery(
    [QUERY_KEYS.GET_PREVIEW_DATA, { ...params }],
    () => getPreviewData(params),
    {
      enabled: params.enabled,
    }
  );

  return { data: data?.data, isLoading: isLoading || isFetching, error };
};
