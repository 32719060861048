import React, { PropsWithChildren, useState } from "react";
import { createPortal } from "react-dom";
import { Tooltip } from "react-tooltip";
import { useGetPreviewData } from "./api/hooks/useGetPreviewData";
import { Vertical } from "../../types/verticalTypes";
import CustomLoadingOverlay from "../PantheonLoading";
import ItemImage from "../ItemImage";
import { formattedTrendBadge } from "../../utils/trendIcon";
import { FormField } from "@cloudscape-design/components";
import { Area, AreaChart, ResponsiveContainer } from "recharts";
import { VerticalIcon } from "../VerticalIcon";
import { getRottenTomatoesCriticScoreParams } from "../../modules/item/pages/components/Scores/RTCriticScore";
import { getRottenTomatoesAudienceScoreParams } from "../../modules/item/pages/components/Scores/RTAudienceScore";

const prefixMap = {
  "film": Vertical.Movies,
  "series": Vertical.Series,
  "game": Vertical.Games,
  "franchise": Vertical.Franchises,
};

const rankLabelMap = {
  [Vertical.Movies]: "Movies Rank",
  [Vertical.Series]: "Series Rank",
  [Vertical.Games]: "Games Rank",
};

interface HoverPreviewProps {
  ipId: string;
  [key: string]: any;
}

const HoverPreview: React.FC<PropsWithChildren<HoverPreviewProps>> = ({
  ipId,
  children,
  ...props
}) => {

  const hoverPreviewId = `hover-preview-${ipId}`;
  const vertical = prefixMap[ipId.split("-")[0]];

  const [isOpen, setIsOpen] = useState(false);

  const { data, isLoading, error } = useGetPreviewData({ ipId, enabled: isOpen });

  if (vertical === Vertical.Franchises) return children;

  return (
    <>
      <a
        data-tooltip-id={hoverPreviewId}
        data-tooltip-delay-show={600}
        data-tooltip-place="top-start"
        style={{
          lineHeight: "normal",
          ...props.style,
        }}
        {...props}
      >
        {children}
      </a>
      {createPortal(
        (
          <Tooltip 
            id={hoverPreviewId} 
            setIsOpen={setIsOpen}
            opacity={1.0}
            disableStyleInjection={true}
            className="z-1000"
            render={({ content, activeAnchor }) => (
              <>
                <div className="p-2 z-1000 h-48 min-w-96 w-fit bg-[#0f161f] rounded-lg shadow-lg border border-gray-500/70">
                  {isLoading ? (
                    <div className="flex items-center justify-center h-full text-white">
                      <CustomLoadingOverlay />
                    </div>
                  ) : data ? (
                    <div className="flex gap-2 w-full h-full text-slate-100">
                      <ItemImage ipId={ipId} vertical={vertical} imageUrl={data.image_url} className="rounded-md h-full" size="medium" />
                      <div className="flex flex-col w-full h-full gap-1">
                        <div className="flex gap-2 items-center mt-[-0.25rem]">
                          <div className="text-lg font-bold max-w-80 truncate">{data.ip}</div>
                          <div className="flex gap-2 items-center">
                            <VerticalIcon vertical={vertical} className="w-4 h-4 text-slate-400" />
                            {data.release_date?.length > 0 && (
                              <div className="text-xs text-slate-400">{data.release_date.split("-")[0]}</div>
                            )}
                          </div>
                        </div>
                        <div className="text-xs text-slate-400 flex gap-2 mt-[-0.25rem]">
                          {data.steam_rating_description && (
                            <div className="h-4 flex gap-1 items-center mb-1">
                              <img src="/svg/steam.svg" className="h-full" width="16" height="16" />
                              <span>{data.steam_rating_description}</span>
                            </div>
                          )}
                          {data.metacritic_rating && (
                            <div className="h-4 flex gap-1 items-center mb-1">
                              <img src="/images/Metacritic_M.png" className="h-full" width="16" height="16" />
                              <span>{data.metacritic_rating}/100</span>
                            </div>
                          )}
                          {data.imdb_vote_average && (
                            <div className="h-4 flex gap-1 items-center mb-1">
                              <img src="/svg/imdb.svg" className="h-full" width="32" height="16" />
                              <span>{data.imdb_vote_average}/10</span>
                            </div>
                          )}
                          {data.rtCriticSummary && data.rtCriticSummary.score && (
                            <div className="h-4 flex gap-1 items-center mb-1">
                              <img src={getRottenTomatoesCriticScoreParams(data.rtCriticSummary).icon} className="h-full" width="16" height="16" />
                              <span>{data.rtCriticSummary.score}%</span>
                            </div>
                          )}
                          {data.rtFanSummary && data.rtFanSummary.score && (
                            <div className="h-4 flex gap-1 items-center mb-1">
                              <img src={getRottenTomatoesAudienceScoreParams(data.rtFanSummary).icon} className="h-full" width="16" height="16" />
                              <span>{data.rtFanSummary.score}%</span>
                            </div>
                          )}
                        </div>
                        <div className="w-full bg-slate-400/10 rounded-md p-2">
                          <div className="grid grid-cols-3">
                            <FormField
                              label={<div className="text-xs font-normal text-slate-400">Latest day</div>}
                              stretch
                              className="border-r border-gray-500/70 mr-2 pr-2"
                            >
                              <div className="flex gap-1 w-full text-slate-100">
                                <b>#{data.daily_rank?.toLocaleString() ?? "N/A"}</b>
                                {formattedTrendBadge(data.daily_rank_change)}
                              </div>
                            </FormField>
                            <FormField
                              label={<div className="text-xs font-normal text-slate-400">Last 7d</div>}
                              stretch
                            >
                              <div className="flex gap-1 w-full text-slate-100">
                                <b>#{data.weekly_rank?.toLocaleString() ?? "N/A"}</b>
                                {formattedTrendBadge(data.weekly_rank_change)}
                              </div>
                            </FormField>
                            <FormField
                              label={<div className="text-xs font-normal text-slate-400">Last 30d</div>}
                              stretch
                              className="border-l border-gray-500/70 ml-2 pl-2"
                            >
                              <div className="flex gap-1 w-full text-slate-100">
                                <b>#{data.monthly_rank?.toLocaleString() ?? "N/A"}</b>
                                {formattedTrendBadge(data.monthly_rank_change)}
                              </div>
                            </FormField>
                          </div>
                        </div>
                        <div className="w-full h-full mt-2">
                          <ResponsiveContainer>
                            <AreaChart
                              data={data.month_score.map((score) => ({ score }))}
                              margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                            >
                              <defs>
                                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                  <stop offset="5%" stopColor="#0972d3" stopOpacity={0.8} />
                                  <stop offset="95%" stopColor="#0972d3" stopOpacity={0} />
                                </linearGradient>
                              </defs>
                              <Area type="monotone" dataKey="score" stroke="#73a8dd" fillOpacity={1} fill="url(#colorUv)" />
                            </AreaChart>
                          </ResponsiveContainer>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <style>
                  {`
                  #${hoverPreviewId} {
                    padding: 0 !important;
                    background: none !important;
                    z-index: 1000 !important;
                  }
                  #${hoverPreviewId} .react-tooltip-arrow {
                    background: #0f161f !important;
                    border-right: 1px solid rgba(107, 114, 128, 0.7) !important;
                    border-bottom: 1px solid rgba(107, 114, 128, 0.7) !important;
                    width: 8px !important;
                    height: 8px !important;
                  }
                  [class*='react-tooltip__place-top']#${hoverPreviewId} > .react-tooltip-arrow {
                    transform: rotate(45deg);
                  }
                  [class*='react-tooltip__place-right']#${hoverPreviewId} > .react-tooltip-arrow {
                    transform: rotate(135deg);
                  }
                  [class*='react-tooltip__place-bottom']#${hoverPreviewId} > .react-tooltip-arrow {
                    transform: rotate(225deg);
                  }
                  [class*='react-tooltip__place-left']#${hoverPreviewId} > .react-tooltip-arrow {
                    transform: rotate(315deg);
                  }
                  `}
                </style>
              </>
            )}
          />
        ),
        document.body
      )}
    </>
  );
};

export default HoverPreview;