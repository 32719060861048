import React, { forwardRef, PropsWithRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Vertical } from '../../types/verticalTypes';
import { Spinner } from '@cloudscape-design/components';
import NewsItem from './NewsItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useGetNewsRiverDataPromise } from './api/hooks/useGetNewsRiverDataPromise';
import { useQueryClient } from 'react-query';
import { QUERY_KEYS } from './api/constants';
import { SERVICES, TYPES } from '../../config-global';
import { ApiURLParams } from '../../services/generic_v2/requests';
import { useGenericPreference_V2 } from '../../services/generic_v2/hooks/useGenericPreference_V2';

export interface NewsRiverProps {
  startTimestamp?: number;
  endTimestamp?: number;
  pageSize?: number;
  ipIds?: Array<string>;
  keywords?: Array<string>;
  verticals?: Array<Vertical>;
  pullLatest?: boolean;
  showArticleCount?: boolean;
  showHighlights?: boolean;
  useFullPageScrollTarget?: boolean;
  onResponseChange?: (response: any) => void;
  [key: string]: any;
};

const NewsRiver: React.FC<PropsWithRef<NewsRiverProps>> = forwardRef(({
  startTimestamp,
  endTimestamp,
  pageSize,
  ipIds,
  keywords,
  verticals,
  pullLatest=false,
  showArticleCount=true,
  showHighlights=true,
  useFullPageScrollTarget=false,
  onResponseChange,
  ...props
}, ref) => {

  const apiParams = { type: TYPES.PERSONAL, service: SERVICES.DASHBOARDS, module: "news-river" } as ApiURLParams;

  const paramsState = useRef(null);

  const [cursor, setCursor] = useState<number>(0);
  const [listData, setListData] = useState<any>([]);
  const [totalArticles, setTotalArticles] = useState<number>(null);
  const [timeseriesData, setTimeseriesData] = useState<any>([]);
  const [duplicateCount, setDuplicateCount] = useState<number>(0);

  const { getNewsRiverData, isLoading, error } = useGetNewsRiverDataPromise();

  const {
    preferenceData,
    savePreference,
  } = useGenericPreference_V2({
    apiParams,
  });

  const queryClient = useQueryClient();

  const highlights = useMemo(() => {
    const highlights = keywords?.map(k => k.toLowerCase()) ?? [];
    const highlightedIps = listData.map((o: any) => o.linked_ips).flat().filter((o: any) => o && ipIds.includes(o.ip_id)).map((o: any) => o.ip.toLowerCase());
    highlights.push(...highlightedIps);
    return highlights;
  }, [keywords, ipIds, listData]);

  const fetchMoreData = (reset) => {
    const existingListData = reset ? [] : listData;
    const existingCursor = reset ? 0 : cursor;
    getNewsRiverData({
      startTimestamp,
      endTimestamp,
      limit: pageSize,
      offset: existingCursor,
      ipIds,
      keywords,
      exclude: preferenceData?.blocked_tags,
      excludeFields: ["tags"],
      verticals,
      latest: pullLatest,
    }).then((response: any) => {
      let newListData = [...existingListData, ...response.data.results].sort((a, b) => b.timestamp - a.timestamp);
      const origLength = newListData.length;
      newListData = newListData.filter((o, i) => i === newListData.findIndex(obj => obj.link === o.link || (obj.timestamp === o.timestamp && o.parent === o.parent)));
      const duplicateCount = origLength - newListData.length;
      setDuplicateCount(duplicateCount);
      setListData(newListData);
      setCursor(existingCursor + pageSize);
      setTotalArticles(response.data.total);
      setTimeseriesData(response.data.timeseries);
    });
  };

  useEffect(() => {
    onResponseChange && onResponseChange({ data: listData, totalArticles, timeseriesData, isLoading, error });
  }, [listData, totalArticles, timeseriesData, isLoading, error]);

  useEffect(() => {
    const newParamsState = { 
      startTimestamp, 
      endTimestamp, 
      pageSize, 
      ipIds, 
      keywords, 
      exclude: preferenceData?.blocked_tags ?? [],
      verticals,
    };
    if (JSON.stringify(newParamsState) === JSON.stringify(paramsState.current)) return;
    paramsState.current = newParamsState;
    setCursor(0);
    setTotalArticles(null);
    setTimeseriesData([]);
    setListData([]);
    fetchMoreData(true);
  }, [startTimestamp, endTimestamp, pageSize, ipIds, keywords, verticals, preferenceData]);

  useEffect(() => {

  }, [preferenceData]);

  useImperativeHandle(ref, () => ({
    refresh: () => {
      queryClient.invalidateQueries(QUERY_KEYS.GET_NEWS_RIVER_DATA);
      setCursor(0);
      setTotalArticles(null);
      setTimeseriesData([]);
      setListData([]);
      fetchMoreData(true);
    },
  }));

  return (
    <div id="news-river-container" style={{ position: "relative", height: "100%", overflowY: listData.length === 0 ? "none" : "auto", ...props.style }} {...props}>
      {error ? (
        <div>Error</div>
      ) : (
        <div className="flex flex-col gap-4">
          {(showArticleCount && totalArticles != null) && (
            <div className="text-xs dark:text-slate-400 text-slate-600">
              {`Found ${totalArticles.toLocaleString() + (totalArticles === 10000 ? "+" : "")} articles`}
            </div>
          )}
          <InfiniteScroll
            dataLength={listData.length}
            next={() => fetchMoreData(false)}
            hasMore={totalArticles == null || (listData.length < (totalArticles - duplicateCount))}
            loader={
              isLoading && <div className="flex justify-center my-8">
                <Spinner size="large" />
              </div>
            }
            scrollableTarget={useFullPageScrollTarget ? null : "news-river-container"}
          >
            {listData.length > 0 ? (
              <div className="flex flex-col gap-4 w-full">
                {listData.map(item => (
                  <NewsItem
                    key={item.id}
                    title={item.title}
                    description={item.description}
                    publishedTimestamp={item.timestamp}
                    link={item.link}
                    sourceName={item.parent}
                    sourceUrl={item.url}
                    tags={item.tags}
                    linkedIps={item.linked_ips}
                    highlights={showHighlights ? highlights : null}
                  />
                ))}
              </div>
            ) : !isLoading && (
              <div className="w-full h-8 flex justify-center items-center">No results</div>
            )}
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
});

export default NewsRiver;