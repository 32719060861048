import { Icon, Link } from "@cloudscape-design/components";
import React, { useState, useCallback, useMemo } from "react";
import renderTrend from "../../../../../../utils/trendIcon";
import { CHART_LABELS } from "../../../../../platforms/hooks/useGridConfig";
import HoverPreview from "../../../../../../components/HoverPreview/HoverPreview";

const metadataColumnDefs = {
  [CHART_LABELS.FRANCHISE]: {
    headerName: "Franchise",
    field: "franchise",
    cellDataType: "object",
  },
  [CHART_LABELS.GENRE]: {
    headerName: "Genre",
    field: "genre",
    cellDataType: "object",
  },
  [CHART_LABELS.CUSTOM_TAGS]: {
    headerName: "Custom Tags",
    field: "cTags",
    cellDataType: "object",
  },
  [CHART_LABELS.COUNTRIES]: {
    headerName: "Countries",
    field: "countries",
    cellDataType: "object",  
  },
  [CHART_LABELS.COUNTRIES]: {
    headerName: "Countries",
    field: "countries",
    cellDataType: "object",
  },
  [CHART_LABELS.DISTRIBUTORS]: {
    headerName: "Distributors",
    field: "distributors",
    cellDataType: "object",
  },
  [CHART_LABELS.PRODUCTION]: {
    headerName: "Production",
    field: "production",
    cellDataType: "object",
  },
  [CHART_LABELS.OTT]: {
    headerName: "OTT",
    field: "providers",
    cellDataType: "object",
  },
};

export const useGridConfig = ({
  additionalColumns,
  selectedColumns,
}) => {

  const renderDailyRank = useCallback((params: any) =>
    renderTrend(params, "daily_rank", "daily_rank_change", "daily_rank_trend"),
    []);

  const renderWeeklyRank = useCallback((params: any) =>
    renderTrend(params, "weekly_rank", "weekly_rank_change", "weekly_rank_trend"),
    []);

  const renderMonthlyRank = useCallback((params: any) =>
    renderTrend(params, "monthly_rank", "monthly_rank_change", "monthly_rank_trend"),
    []);

  const renderTitle = useCallback((params: any) => {
    if (!params.value) return null;
    const ipId = params.data.ip_id;
    const iconVariant = params.data.tracked === true ? "success" : "disabled";

    return (
      <div className="flex items-center">
        <Icon
          name={params.data.ip_id != null ? "status-positive" : "status-negative"}
          variant={iconVariant}
        />
        <div className="ml-1">
          <HoverPreview ipId={ipId}>
            <Link href={`/item/${ipId}`}>
              <span>{params.data.ip}</span>
            </Link>
          </HoverPreview>
          <span className="text-xs text-slate-500 ml-1">{params.data.release_date.substring(0, 4)}</span>
        </div>
      </div>
    );
  }, []);

  const renderNumber = useCallback((params: any) => {
    if (!params.value) return null;
    return <span>{params.value.toLocaleString()}</span>;
  }, []);

  const allColumnDefs = useMemo(() => [
    {
      headerName: "Daily Rank",
      field: "daily_rank",
      pinned: "left",
      maxWidth: 150,
      cellDataType: "number",
      minWidth: 130,
      sortable: true,
      checkboxSelection: true,
      cellRenderer: renderDailyRank,
      valueGetter: (params: any) => parseInt(params.data.daily_rank),
    },
    {
      headerName: "7-Day Rank",
      field: "weekly_rank",
      pinned: "left",
      maxWidth: 100,
      cellDataType: "number",
      minWidth: 100,
      sortable: true,
      cellRenderer: renderWeeklyRank,
      valueGetter: (params: any) => parseInt(params.data.weekly_rank),
    },
    {
      headerName: "30-Day Rank",
      field: "monthly_rank",
      pinned: "left",
      maxWidth: 100,
      cellDataType: "number",
      minWidth: 100,
      sortable: true,
      cellRenderer: renderMonthlyRank,
      valueGetter: (params: any) => parseInt(params.data.monthly_rank),
    },
    {
      field: "ip",
      headerName: "Title",
      pinned: "left",
      cellDataType: "text",
      minWidth: 100,
      cellRenderer: renderTitle,
    },
    {
      headerName: CHART_LABELS.WIKIPEDIA_PAGE_VIEWS,
      field: "wiki_views",
      cellDataType: "number",
      minWidth: 100,
      cellRenderer: renderNumber,
    },
    {
      headerName: CHART_LABELS.IMDB_VOTES,
      field: "imdb_votes",
      cellDataType: "number",
      minWidth: 100,
      cellRenderer: renderNumber,
    },
    {
      headerName: CHART_LABELS.IMDB_RATING,
      field: "imdb_rating",
      cellDataType: "number",
    },
    {
      headerName: CHART_LABELS.YOUTUBE_VIEWS,
      field: "youtube_views",
      cellDataType: "number",
      minWidth: 100,
      cellRenderer: renderNumber,
    },
    {
      headerName: CHART_LABELS.ROTTEN_TOMATOES_VOTES,
      field: "rt_votes",
      cellDataType: "number",
      minWidth: 100,
      cellRenderer: renderNumber,
    },
    {
      headerName: CHART_LABELS.ROTTEN_TOMATOES_RATING,
      cellDataType: "number",
      field: "rt_rating"
    },
    {
      headerName: CHART_LABELS.PIRACY_DOWNLOADS,
      field: "piracy_downloads",
      cellDataType: "number",
      minWidth: 100,
      cellRenderer: renderNumber,
    },
  ], [renderDailyRank, renderWeeklyRank, renderMonthlyRank, renderTitle, renderNumber]);

  const sparklineColumnDefs = useMemo(() => [
    {
      field: "month_score",
      headerName: "Trend",
      cellRenderer: "agSparklineCellRenderer",
      cellRendererParams: {
        sparklineOptions: {
          type: "area",
          fill: "#0972d3",
          highlightStyle: {
            fill: "#033160",
          },
        },
      },
    },
  ], []);

  const columnDefs = useMemo(() => {
    return [...allColumnDefs, ...Object.values(metadataColumnDefs), ...sparklineColumnDefs].map(col => {
      if (!additionalColumns.includes(col.headerName) && !metadataColumnDefs.hasOwnProperty(col.headerName)) {
        return { ...col, hide: false };
      } else if (selectedColumns.map(x => x.value).includes(col.headerName)) {
        return { ...col, hide: false };
      } else {
        return { ...col, hide: true };
      }
    });
  }, [selectedColumns, allColumnDefs, additionalColumns]);

  const defaultColDef = useMemo(() => ({
    flex: 1,
    minWidth: 100,
    sortable: true,
    suppressHeaderMenuButton: true,
    suppressHeaderFilterButton: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    filter: true,
    cellStyle: { fontSize: '12px' },
  }), []);

  return {
    columnDefs,
    defaultColDef,
  };
};
