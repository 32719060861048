import { Icon, Link } from "@cloudscape-design/components";
import React from "react";
import { FC } from "react";

interface IMDbScoreProps {
  score: number;
  votes: number;
  id: string;
  compact?: boolean;
}

const IMDbScore: FC<IMDbScoreProps> = ({ score, votes, id, compact=false }) => {
  return (
    <div className="h-full flex flex-col justify-center">
      <div className="flex items-center space-x-2">
        {score ? (
          <>
            <img src="/svg/imdb.svg" alt="Votes" className={compact ? "h-6 w-6" : "h-12 w-12"} />
            <div className="text-black dark:text-slate-100">
              <div className={`dark:text-slate-50 leading-[1.25rem] ${compact ? "" : "text-lg font-bold"}`}>
                {score}
              </div>
              {id ? (
                <Link 
                  href={`https://www.imdb.com/title/${id}/ratings/?ref_=tt_ov_rt`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="text-xs font-bold">IMDb Rating <Icon name="external" size="inherit" /></div>
                </Link>
              ) : null}
              <div className="text-xs italic dark:text-slate-400">{votes.toLocaleString()} votes</div>
            </div>
          </>
        ) : (
          <>
            <img src="/svg/imdb.svg" alt="Votes" className={compact ? "h-6 w-6 grayscale" : "h-12 w-12 grayscale"} />
            <div className={`dark:text-slate-50 leading-[1.25rem] ${compact ? "" : "text-lg font-bold"}`}>
              -
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default IMDbScore;
