import React, { useState } from "react";
import { Autosuggest, TokenGroup } from "@cloudscape-design/components";
import axiosInstance from "../../utils/axios";

const TagSelector = ({ 
  tags, 
  onTagsChanged, 
  platformName, 
  platform,
  placeholder,
}) => {

  // const origTags = (typeof tags === "string" ? tags.split(",") : tags).map(x => x.trim()).filter(x => x.length > 0);
  const origTags = (typeof tags === "string" ? tags.split(",") : (tags || [])).map(x => x.trim()).filter(x => x.length > 0);

  const [ selectedTags, setSelectedTags ] = useState(origTags);
  const [ inputValue, setInputValue ] = useState("");

  const [ isLoading, setIsLoading ] = useState(false);
  const [ autosuggestOptions, setAutosuggestOptions ] = useState([]);

  const getAutosuggestOptions = (query) => {
    if (!query || query.length < 2) {
      return;
    }
    setIsLoading(true);
    axiosInstance.get(
      `/autocomplete?platform=${platform}&q=${query}`
    ).then(response => {
      setAutosuggestOptions(response.data);
      setIsLoading(false);
    });
  };

  const addTag = (tag) => {
    tag = tag.charAt(0) === "#" ? tag.substring(1) : tag;
    if (!tag || tag.length === 0) {
      return;
    }
    const newTags = [...new Set([...selectedTags, tag])].map(x => x.trim());
    setSelectedTags(newTags);
    onTagsChanged(newTags.filter(x => x.length > 0));
    setInputValue("");
  };

  return (
    <div>
      <Autosuggest
        onSelect={({ detail }) => addTag(detail.selectedOption?.value || detail.value)}
        onChange={({ detail }) => setInputValue(detail.value)}
        onLoadItems={({ detail }) => platform ? getAutosuggestOptions(detail.filteringText) : null}
        onBlur={() => addTag(inputValue)}
        statusType={isLoading ? "loading" : "finished"}
        value={inputValue}
        options={autosuggestOptions}
        filteringType="auto"
        placeholder={placeholder ?? `Add ${platformName} tags`}
        empty="Start typing to add a tag"
        enteredTextLabel={(value) => `Use: "${value}"`}
      />
      <TokenGroup
        items={selectedTags.map(x => ({ label: x }))}
        onDismiss={({ detail: { itemIndex } }) => {
          const newTags = [...new Set([
            ...selectedTags.slice(0, itemIndex),
            ...selectedTags.slice(itemIndex + 1)
          ])];
          setSelectedTags(newTags);
          onTagsChanged(newTags.filter(x => x.length > 0));
        }}
        limit={3}
        i18nStrings={{
          limitShowFewer: "Show less",
          limitShowMore: "Show more",
        }}
      />
    </div>
  );
};

export default TagSelector;