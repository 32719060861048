import { Container, Grid, Header } from "@cloudscape-design/components";
import React, { useMemo } from "react";
import TopWidget from "./TopWidget";
import NewsRiver from "../../../../../components/news-river/NewsRiver";
import moment from "moment";

const gridDefinition = Array.from({ length: 3 }, () => ({
  colspan: { default: 12, s: 4 },
}));

const baseFields = "s.ip_id, s.ip, s.image_url, s.production, s.providers, s.release_date";

const getFields = (timePeriod: 'daily' | 'weekly' | 'monthly') => {
  const periodFields = {
    daily: "s.daily_rank, s.daily_rank_change, s.daily_rank_trend, s.month_score",
    weekly: "s.weekly_rank, s.weekly_rank_change, s.weekly_rank_trend, s.month_score",
    monthly: "s.monthly_rank, s.monthly_rank_change, s.monthly_rank_trend, s.month_score"
  }[timePeriod];
  
  return `${baseFields}, ${periodFields}`;
};

const getTopEndpoint = (vertical: 'movies' | 'series' | 'gaming', timePeriod: 'daily' | 'weekly' | 'monthly') => {
  const endpoints = {
    movies: `/pql?key=platform/global_movies_${timePeriod[0]}_top&file_type=P`,
    series: `/pql?key=platform/global_series_${timePeriod[0]}_top&file_type=P`,
    gaming: `/pql?key=platform/global_gaming_${timePeriod[0]}_top&file_type=P`
  };
  return `${endpoints[vertical]}&size=10&sort=${timePeriod}_rank:asc`;
};

const getFranchisePayload = (franchiseName: string, timePeriod: 'daily' | 'weekly' | 'monthly') => ({
  sql: `SELECT ${getFields(timePeriod)} FROM s3object s WHERE s.franchise LIKE '%${franchiseName}%' LIMIT 10`
});

export const Overview = ({
  itemData,
}) => {

  const timePeriod = "weekly";

  return (
    <div className="flex flex-col gap-4">
       <Grid gridDefinition={gridDefinition}>
        {[
          { value: "movies", label: "Top Movies" },
          { value: "series", label: "Top Series" },
          { value: "gaming", label: "Top Video Games" }
        ].map(option => (
          <TopWidget
            key={option.value}
            title={option.label}
            endpoint={getTopEndpoint(option.value as any, timePeriod)}
            payload={getFranchisePayload(itemData.name, timePeriod)}
          />
        ))}
      </Grid>
      <Container
        header={
          <Header>Latest News</Header>
        }
      >
        <div className="h-80 overflow-y-auto overflow-x-none">
          <NewsRiver
            startTimestamp={moment().subtract(7, "days").unix()}
            endTimestamp={moment().unix()}
            pageSize={50}
            ipIds={[itemData.items.map(item => item.ip_id)]}
            showArticleCount={true}
            showHighlights={false}
            useFullPageScrollTarget={false}
          />
        </div>
      </Container>
    </div>
  );
};